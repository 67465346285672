const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel', 'Option'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_tire_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'wheel_tire_diameter'];
const ignoreFields = [...wheelsFields, ...tiresFields];

// the theme has a currency selector for markets, the URL should contain a culture code based on the selected currency
const cultureCode = globalThis.Shopify?.routes?.root.slice(0, -1) ?? '';
const searchPageUrl = `${cultureCode}/search`;
const categorySelectionPageUrl = `${cultureCode}/collections`;
const brandSelectionPageUrl = `${cultureCode}/pages/brands`;

const getPageType = (defaults) => {
  return window.location.href.indexOf('vendors') > -1
    ? 'brand'
    : window.document.querySelector('body.template-collection')
      ? 'category'
      : window.document.querySelector('body.template-index')
        ? 'home'
        : defaults.getter();
};

const getLocalPreselection = (pageType, defaults) => {
  if (pageType === 'category') {
    return [{ field: 'collection_ids', term: window.Convermax.collectionId }];
  }
  return defaults.getter(pageType);
};

const getFitmentSearchTitle = () => {
  const { pageType } = window.ShopifyAnalytics.meta.page;

  const title = window.document
    .querySelector('body.template-collection .boost-pfs-filter-collection-header.collection__title.heading')
    ?.textContent.trim();

  return pageType === 'collection' || pageType === 'vendors'
    ? title
    : pageType === 'product'
      ? window.ShopifyAnalytics.meta.product.type
      : 'Search Results';
};

const updateCallback = () => {
  if (window.affirm) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }
};

export default {
  platform: 'shopify',
  searchPageUrl,
  InitFunc: () => {
    window.Convermax.fitmentSearch = { title: getFitmentSearchTitle() };
  },
  SearchRequestDefaults: {
    pageSize: 36,
    extra: {
      doNotHideWheelsTiresFacets: window.document.body.classList.contains('template-index'),
    },
  },
  page: {
    getPageType,
  },
  getLocalPreselection,
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: true,
    isVehicleSelectionIsolated: true,
    getFitmentSearchTitle: () => window.Convermax.fitmentSearch.title,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1' }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        selector: '#cm_category-main-content',
        class: 'cm_category-main-content collection__toolbar',
      },
      template: 'MainContent',
    },
    {
      name: 'CategoryFacetPanel',
      type: 'SearchPage',
      location: '#cm_category-facet-panel',
      template: 'FacetPanelContainer',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'WheelTireVehicleInfo',
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchResult',
      instantInfiniteScroll: window.innerWidth <= 999,
      updateCallback,
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: '.header__search-bar-wrapper',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.header__action-item-link[data-action="toggle-search"]',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '.cm_tab-content.cm_vehicle-search',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'HomeWheelBar',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_wheel-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: wheelsFields,
    },
    {
      name: 'HomeTireBar',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_tire-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
    },
    {
      name: 'HomeSearchBox',
      type: 'SearchBox',
      location: '.cm_tab-content.cm_keyword-search',
      template: 'SearchBox',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        firstChildOf: 'body:not(.template-index) main#main',
        class: 'cm_vehicle-widget__header-container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 1000,
    },
    {
      name: 'VehicleLabel',
      type: 'VehicleWidget',
      location: {
        replace: 'body.template-collection .collection__title.heading',
      },
      template: 'fitmentSearch/vehicleLabelContainer',
    },
    {
      name: 'VehicleLabel_search',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.header__action-item--cart',
        class: 'header__action-item',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        insertAfter: 'body.template-product .affirm-as-low-as',
      },
      template: 'fitmentSearch/verifyFitment',
      initCollapsed: true,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_category-selection-page',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brand-selection-page',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'FacetDialogButton',
      type: 'DialogButton',
      dialogName: 'FacetDialog',
      onClick: () => window.Convermax.discardVehicle(),
    },
  ],
};


        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function MainContentRT () {
    return _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t have any\n    ',
                !!this.vehicleString ? [
                    ' parts that fit ',
                    _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                '\n    Press ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '26721'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3065'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3280'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3509'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '36550' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '37420' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '38250' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '36204' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.VehicleLabel_search(function () {
            return window.location.pathname === '/search' ? _createElement('div', {
                'className': 'cm_vehicle-widget cm_vehicle-widget__page-title cm_VehicleLabel_search',
                'key': '0'
            }, _createElement('h1', { 'className': 'collection__title h1 heading' }, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label-container cmTemplate_locked',
                'key': '185'
            }, _createElement('span', { 'className': 'cm_vehicle-widget_title' }, this.fitmentSearchTitle, ' '), _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, 'for the ', this.vehicleString)) : null, this.template !== 'locked' ? [_createElement('span', {
                    'className': 'cm_vehicle-widget_title',
                    'key': '4731'
                }, this.fitmentSearchTitle)] : null)) : null;
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.WheelTireVehicleInfo(function () {
            function scopeWheelData1() {
                var wheelData = this.data.WheelData;
                return [wheelData ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_wheels-data',
                        'key': '15341'
                    }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment' }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment__label' }, 'Recommended Wheel Sizes')), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_axle_container' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_front-axle' }, wheelData.RearAxle ? _createElement('span', {
                        'className': 'cm_wheel-tire-vehicle-info_axle-title',
                        'key': '2013'
                    }, 'Front Sizes:') : null, wheelData.FrontAxle.Diameter ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '2126'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Diameter:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Diameter)) : null, wheelData.FrontAxle.Width ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '2452'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Width:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Width)) : null, wheelData.FrontAxle.Offset ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '2769'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Offset:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.FrontAxle.Offset)) : null), wheelData.RearAxle ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_rear-axle',
                        'key': '3106'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_axle-title' }, 'Rear Sizes:'), wheelData.RearAxle.Diameter ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '3283'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Diameter:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Diameter)) : null, wheelData.RearAxle.Width ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '3607'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Width:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Width)) : null, wheelData.RearAxle.Offset ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_size-item',
                        'key': '3922'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Offset:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.RearAxle.Offset)) : null) : null), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_attributes_container' }, wheelData.BoltPattern ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_bolt-pattern',
                        'key': '4347'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Bolt Pattern:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.BoltPattern)) : null, wheelData.HubBore ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_hubbore',
                        'key': '4658'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hub Bore:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.HubBore)) : null, wheelData.Hardware ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_hardware',
                        'key': '4952'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hardware:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Hardware)) : null, wheelData.Thread ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_thread',
                        'key': '5249'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Thread:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Thread)) : null, wheelData.Hex ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_hex',
                        'key': '5538'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Hex:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.Hex)) : null, wheelData.TPMS ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_tpms',
                        'key': '5815'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'TPMS:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.TPMS)) : null, wheelData.SeatType ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_seat-type',
                        'key': '6096'
                    }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__label' }, 'Seat Type:'), _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_size-item__value' }, wheelData.SeatType)) : null)) : null];
            }
            function repeatTireSize2(tireSizes, tireSize, tireSizeIndex) {
                return _createElement('span', {
                    'className': 'cm_wheel-tire-vehicle-info_tire-sizes__item',
                    'key': tireSize
                }, '\n                ', tireSize, '\n              ');
            }
            function scopeTireSizes3() {
                var tireSizes = this.data.TireSizes;
                return [tireSizes?.length > 0 ? _createElement('div', {
                        'className': 'cm_wheel-tire-vehicle-info_tires-data',
                        'key': '64421'
                    }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment' }, _createElement('span', { 'className': 'cm_wheel-tire-vehicle-info_recommended-fitment__label' }, 'Recommended Tire Sizes')), _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_tire-sizes__container' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_wheel-tire-vehicle-info_tire-sizes' },
                        _map(tireSizes, repeatTireSize2.bind(this, tireSizes))
                    ]))) : null];
            }
            return _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info cm_desktop-hide cm_WheelTireVehicleInfo' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                'className': 'cm_wheel-tire-vehicle-info_header cm_collapsible_header facettitle',
                'onClick': this.toggleCollapsed
            }, '\n    Vehicle Wheel/Tire Info\n    ', this.collapsed ? [_createElement('svg', {
                    'className': 'cm_icon cm_icon-angle left',
                    'height': '20px',
                    'role': 'img',
                    'viewBox': '39 30 565 565',
                    'aria-hidden': 'true',
                    'key': '2540'
                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.collapsed ? [_createElement('svg', {
                    'className': 'cm_icon cm_icon-angle down',
                    'height': '20px',
                    'role': 'img',
                    'viewBox': '39 30 565 565',
                    'aria-hidden': 'true',
                    'key': '5730'
                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', { 'className': 'cm_collapsible-elem facetbody' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_inner-container' }, [this.autoSyncVisualization(function () {
                    return _createElement('div', { 'className': 'cm_autoSyncVisualization' }, _createElement('div', { 'className': 'cm_vehicle-visualization-container' }, this.visualization));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_title' }, _createElement('div', { 'className': 'cm_wheel-tire-vehicle-info_title-vehicle' }, this.vehicleString)), scopeWheelData1.apply(this, []), scopeTireSizes3.apply(this, [])))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.UpsizeDownsizeFacetPanel(function () {
            return this.facets.length === 1 && this.isVehicleSelected ? _createElement('div', {
                'className': 'cm_upsize-downsize cm_UpsizeDownsizeFacetPanel',
                'key': '0'
            }, [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'cm_button ' + (this.isSelected ? 'cm_selected cm_btn button button--small button--primary' : 'cm_btn button button--small button--secondary') }, '\n              ', this.value, '\n            ');
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'cm_field-' + this.field }, _createElement('div', { 'className': 'cm_upsize-downsize-facettitle' }, this.name), _createElement('div', { 'className': 'cm_upsize-downsize-facetbody' }, this.template === 'simpleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleFacet',
                                    'key': '165'
                                }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cm_button-container cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                ])) : null));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]) : null;
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'cm_SearchHeader-container' }, [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, _createElement('div', { 'className': 'cm_total-hits' }, _createElement('span', {}, this.totalHits, ' results')), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.FacetDialogButton(function () {
            return _createElement('div', { 'className': 'cm_facetDialogButton  cm_FacetDialogButton' }, _createElement('div', { 'className': 'cm_btn button button--small button--primary' }, 'Filters \n    ', this.withSelection ? [_createElement('svg', {
                    'className': 'cm_icon cm_filter-icon',
                    'viewBox': '-5 0 394 394.00003',
                    'key': '1340'
                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                    'className': 'cm_icon cm_filter-icon',
                    'viewBox': '0 0 247.46 247.46',
                    'key': '8750'
                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]), [this.FacetDialogButton(function () {
            return _createElement('div', { 'className': 'cm_facetDialogButton cm_facetDialogButton__sticky  cm_FacetDialogButton' }, _createElement('div', { 'className': 'cm_btn button button--small button--primary' }, 'FILTER BY SIZE'));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        return _createElement('div', { 'className': 'product-item product-item--vertical 1/3--tablet-and-up 1/4--desk' }, this.on_sale ? _createElement('div', {
                            'className': 'product-item__label-list',
                            'key': '81'
                        }, _createElement('span', { 'className': 'product-label product-label--on-sale' }, '\n      Save ', this.formatPrice(this.compare_at_price - this.price), '\n    ')) : null, _createElement('a', {
                            'href': this.url,
                            'className': 'product-item__image-wrapper' + (this.image2 ? ' product-item__image-wrapper--with-secondary' : '')
                        }, _createElement('div', {
                            'className': 'aspect-ratio aspect-ratio--square',
                            'style': { paddingBottom: '100%' }
                        }, _createElement('img', {
                            'className': 'product-item__primary-image image--fade-in lazyautosizes lazyloaded',
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError
                        }), this.image2 ? _createElement('img', {
                            'className': 'product-item__secondary-image image--fade-in lazyautosizes lazyloaded',
                            'src': this.imageOrDefault(this.resizeImage(this.image2)),
                            'alt': this.removeHTML(this.title),
                            'onError': e => this.onImageError(e, 'image2'),
                            'key': '734'
                        }) : null)), _createElement('div', { 'className': 'product-item__info' }, _createElement('div', { 'className': 'product-item__info-inner' }, _createElement('a', mergeProps({
                            'className': 'product-item__vendor link',
                            'href': this.vendor_url
                        }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('a', mergeProps({
                            'href': this.url,
                            'className': 'product-item__title text--strong link'
                        }, { dangerouslySetInnerHTML: { __html: this.breakWordOnSlash(this.title) } })), _createElement('div', { 'className': 'product-item__price-list price-list' }, this.on_sale ? [
                            _createElement('div', {
                                'className': this.show_set_price ? 'price-container ' : '',
                                'key': '14441'
                            }, _createElement('span', { 'className': 'price price--highlight' }, this.formatPrice(this.price)), _createElement('span', { 'className': 'price price--compare' }, this.formatPrice(this.compare_at_price)), _createElement('span', { 'className': 'price-label' }, '\n              ', this.FitmentType.some(f => f === 'Tire' || f === 'Wheel') ? `per
              ${ this.FitmentType.includes('Tire') ? 'tire' : 'wheel' }` : 'each', '\n            ')),
                            this.show_set_price ? _createElement('div', {
                                'className': 'price-container',
                                'key': '14443'
                            }, _createElement('span', { 'className': 'price price--highlight' }, this.formatPrice(this.price * 4)), _createElement('span', { 'className': 'price price--compare' }, this.formatPrice(this.compare_at_price * 4)), _createElement('span', { 'className': 'price-label' }, 'set of 4')) : null
                        ] : null, !this.on_sale ? [
                            _createElement('div', {
                                'className': this.show_set_price ? 'price-container ' : '',
                                'key': '24181'
                            }, _createElement('span', { 'className': 'price' }, this.formatPrice(this.price)), _createElement('span', { 'className': 'price-label' }, '\n              ', this.FitmentType.some(f => f === 'Tire' || f === 'Wheel') ? `per
              ${ this.FitmentType.includes('Tire') ? 'tire' : 'wheel' }` : 'each', '\n            ')),
                            this.show_set_price ? _createElement('div', {
                                'className': 'price-container',
                                'key': '24183'
                            }, _createElement('span', { 'className': 'price' }, this.formatPrice(this.price * 4)), _createElement('span', { 'className': 'price-label' }, 'set of 4')) : null
                        ] : null), this.inventory_quantity && this.inventory_quantity >= 5 ? _createElement('span', {
                            'className': 'product-item__inventory inventory inventory--high',
                            'key': '3174'
                        }, '\n        IN STOCK - ', this.inventory_quantity >= 16 ? '16+' : `${ this.inventory_quantity }`, '\n      ') : null, this.inventory_quantity && this.inventory_quantity < 5 ? _createElement('span', {
                            'className': 'product-item__inventory inventory inventory--low',
                            'key': '3442'
                        }, '\n        Only ', this.inventory_quantity, ' units left\n      ') : null, this.price ? _createElement('p', {
                            'className': 'affirm-as-low-as',
                            'data-amount': this.price * 100,
                            'data-affirm-type': 'logo',
                            'data-affirm-color': 'blue',
                            'key': '3650'
                        }) : null), _createElement('form', {
                            'method': 'post',
                            'action': '/cart/add',
                            'acceptCharset': 'UTF-8',
                            'className': 'product-item__action-list button-stack',
                            'encType': 'multipart/form-data'
                        }, _createElement('input', {
                            'type': 'hidden',
                            'name': 'quantity',
                            'value': '1'
                        }), _createElement('input', {
                            'type': 'hidden',
                            'name': 'id',
                            'value': this.variant_ids
                        }), this.out_of_stock ? _createElement('button', {
                            'className': 'product-item__action-button button button--small button--disabled',
                            'disabled': true,
                            'key': '4063'
                        }, '\n        Sold out\n      ') : null, !this.out_of_stock ? _createElement('button', {
                            'type': 'submit',
                            'className': 'product-item__action-button product-item__action-button--list-view-only button button--small button--primary',
                            'data-action': 'add-to-cart',
                            'key': '4223'
                        }, '\n        Add to cart\n      ') : null, _createElement('button', {
                            'type': 'button',
                            'className': 'product-item__action-button product-item__action-button--list-view-only button button--small button--ternary hidden-phone',
                            'data-action': 'open-modal',
                            'data-secondary-action': 'open-quick-view',
                            'aria-controls': 'modal-quick-view-search-template',
                            'data-product-url': this.url
                        }, '\n        Quick view\n      '))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'div',
                { 'className': 'product-list product-list--collection product-list--with-sidebar card' + (this.view ? ` ${ this.view }` : '') + ' cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '4950'
                        }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '7690'
                        }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1136'
                    }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1554'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '1816'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2108'
                    }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t have any\n    ',
                        !!this.vehicleString ? [
                            ' parts that fit ',
                            _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                        '\n    Press ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '26721'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3065'
                    }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3280'
                    }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '3509'
                    }, 'We failed to process your request', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '36550' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '37420' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '38250' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '36204' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default btn-inverse cm_btn button button--small button--primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        ', window.Convermax.config?.loadMoreText || `Next ${ this.nextPageSize }`, '\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })]);
}
        export const componentNames = ["cm:message","cm:customMessage","cm:VehicleLabel_search","cm:autoSyncVisualization","cm:WheelTireVehicleInfo","cm:facets","cm:UpsizeDownsizeFacetPanel","cm:SearchHeader","cm:FacetDialogButton","cm:FacetDialogButton","cm:message","cm:loadMoreButton","cm:loadMore","cm:SearchResult"]